import { Page, Component } from "@bloomreach/spa-sdk";
import { BreadcrumbItemProps } from "~/components/UI/Breadcrumbs/breadcrumbTypes";

type MultiValueObject = {
  selectionValues: {
    key: string;
    label: string;
  }[];
};

type RichTextObject = {
  value: string;
};

type Role = {
  title: string;
  name: string;
  description: RichTextObject;
  closingdate: string;
  location: string;
  department: MultiValueObject;
  salary: string;
  cvemail: string;
  roletype: MultiValueObject;
};

export function useDepartmentRoles(page: Page, component: Component, department: string) {
  const pagination = page?.getContent(component?.getModels()?.pagination?.$ref) as any;
  const lastPage = pagination?.getLast().model?.number || 0;
  const itemsPerPage = pagination.getSize() || 5;

  const roles =
    pagination
      ?.getItems()
      ?.map((item: any) => page.getContent(item?.$ref)?.getData())
      .filter(Boolean) || [];

  return {
    roles: roles.map((role: any) => getRoleInfo(role, department)),
    lastPage,
    itemsPerPage,
  };
}

function formatRoleDate(date: string) {
  const dt = new Date(date);

  const year = dt.getFullYear();
  const month = (dt.getMonth() + 1).toString().padStart(2, "0");
  const day = dt.getDate().toString().padStart(2, "0");

  return `${day}.${month}.${year}`;
}

export function getRoleInfo(role: Role, department: string) {
  return {
    ...role,
    description: role?.description?.value,
    date: formatRoleDate(role?.closingdate),
    department: role?.department?.selectionValues[0],
    link: `/careers/vacancies/${department}/${role?.name}`,
    role: role?.roletype?.selectionValues[0].label,
  };
}

export const generateBreadcrumbs = (roleTitle: string | null, route: any): BreadcrumbItemProps[] => {
  const paths = route.path.split("/");
  const initialState = route.path
    .split("/")
    .slice(1, roleTitle ? -1 : undefined)
    .map((path: string, index: number) => {
      return {
        title: (path.charAt(0).toUpperCase() + path.slice(1)).split("-").join(" "),
        href: paths.slice(0, index + 2).join("/"),
      };
    });

  const additionalBreadcrumbs = roleTitle
    ? [
        {
          title: roleTitle.split("-").join(" "),
          href: route.path,
        },
      ]
    : [];

  return [...initialState, ...additionalBreadcrumbs];
};
